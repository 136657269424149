<template>
  <div class="content">
    <base-header class="pb-6">
      <div class="row align-items-center py-4">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">Transações (Extrato)</h6>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--6">
      <div>
        <card class="no-border-card" body-classes="px-0 pb-1" footer-classes="pb-2">
          <div>
            <div class="col-md-12">
              <form>
                <div class="row">
                  <div class="col-md-12">
                    <base-input label="Período" class="float-left">
                      <date-picker v-model="dateRange" valueType="format" style="width: 240px" format="DD/MM/YYYY"
                                   range range-separator=" - " :disabled-date="disableFutureDates">
                        <template v-slot:footer="{ emit }">
                          <div style="text-align: left">
                            <button class="mx-btn mx-btn-text" @click="selectLastDays(emit,7)">
                              Última Semana
                            </button>
                          </div>
                          <div style="text-align: left">
                            <button class="mx-btn mx-btn-text" @click="selectLastDays(emit,15)">
                              15 dias
                            </button>
                          </div>
                          <div style="text-align: left">
                            <button class="mx-btn mx-btn-text" @click="selectLastDays(emit,30)">
                              30 dias
                            </button>
                          </div>
                        </template>

                      </date-picker>
                    </base-input>

                    <div class="float-left invisible ml-3">.</div>

                    <button type="button" @click.prevent="search" class="btn btn-sm btn-primary float-left"
                            style="margin-top:32px">
                      <i class="fas fa-search"></i> BUSCAR
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <all-transactions-table :item-limit="5" :list-items="this.timelineItems"/>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
  import {Table, TableColumn, Select, Option} from 'element-ui';
  import {callWs} from "@/ws.service"
  import {formatDate} from "@/util/date.utils"
  import DatePicker from 'vue2-datepicker'
  import 'vue2-datepicker/locale/pt-br'
  import AllTransactionsTable from '@/components/UIComponents/Dzs/AllTransactionsTable'

  export default {
    components: {
      DatePicker,
      AllTransactionsTable,
      [Select.name]: Select,
      [Option.name]: Option,
      [Table.name]: Table,
      [TableColumn.name]: TableColumn
    },
    mounted() {
      this.callHistoryWs(100)
    },
    data() {
      return {
        startDate: null,
        endDate: null,
        dateRange: [],
        timelineItems: []
      };
    },
    methods: {
      search() {
        if (this.dateRange[0] == null || this.dateRange[1] == null) {
          this.failExecution();
          return;
        }

        let dateStrSplt = this.dateRange[0].split("/");
        this.startDate = new Date(dateStrSplt[2], dateStrSplt[1] - 1, dateStrSplt[0]);
        dateStrSplt = this.dateRange[1].split("/");
        this.endDate = new Date(dateStrSplt[2], dateStrSplt[1] - 1, dateStrSplt[0]);

        if (this.endDate > new Date(this.startDate.getTime() + 30 * 24 * 3600 * 1000)) {
          this.$swal({
            title: 'Atenção!',
            text: 'O período máximo permitido é de 30 dias.',
            buttonsStyling: false,
            customClass: {
              confirmButton: 'btn btn-warning btn-fill'
            },
            icon: 'warning'
          })
          return
        }

        this.callHistoryWs(500, this.startDate, this.endDate)
      },
      callHistoryWs(pageSize,startDate, endDate) {
        callWs("/transaction/transactions-by-period", true,
          "POST", null, true, null,
          {
            pageNumber: 0,
            pageSize: pageSize,
            startDate: startDate != null ? formatDate(startDate, "DD/MM/YYYY") : null,
            endDate: endDate != null ? formatDate(endDate, "DD/MM/YYYY") : null
          },
          this.loadTransactions,
          this.failExecution)
      },
      loadTransactions(response) {
        let transactions = response.data.list
        if (transactions.length <= 0) {
          this.timelineItems = []
          return
        }
        this.timelineItems = transactions
      },
      failExecution() {
        this.$swal({
          title: 'Atenção!',
          text: 'É preciso preencher os campos de data para pesquisar!',
          buttonsStyling: false,
          customClass: {
            confirmButton: 'btn btn-warning btn-fill'
          },
          icon: 'warning'
        })
        return
      },
      selectLastDays(emit, days) {
        const start = new Date();
        const end = new Date();
        start.setTime(start.getTime() - days * 24 * 3600 * 1000);
        const date = [new Date(start.toDateString()), new Date(end.toDateString())];
        emit(date);
      },
      disableFutureDates(date) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return date > today;
      },

    }
  };
</script>
<style>
  .no-border-card .card-footer {
    border-top: 0;
  }
</style>
